import type { Dispatch, ReactElement, SetStateAction } from 'react';

import { Portal } from 'design-system';
import { AnimatePresence, motion } from 'framer-motion';

export const BottomSheet = ({
  isVisible,
  setIsVisible,
  children,
}: {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  children: ReactElement | ReactElement[];
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <Portal onClickBackground={() => setIsVisible(false)}>
          <motion.div
            className="absolute bottom-0 left-1/2 w-full max-w-[500px]"
            initial={{ y: 1000, x: '-50%' }}
            animate={{ y: 0, x: '-50%' }}
            exit={{ y: 1000, x: '-50%' }}
            transition={{ type: 'spring', bounce: 0 }}
          >
            {children}
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  );
};
