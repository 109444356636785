import { NewIcon } from 'design-system';
import { useRouter } from 'next/router';

import { BottomSheet } from '@components';
import { useGetStudyProgress } from '@components/Activation/written-test/queries';
import { useWrittenTestStore } from '@components/Activation/written-test/store';
import type { WrittenTestProblemCategory } from '@pages/api/v2/route/written-test/writtenTestSchema';

import { TEST_BANK_BY_CATEGORIES } from '@constants';

const WRITTEN_TEST_PROBLEM_CATEGORY_LIST = [
  '문장형1답',
  '문장형2답',
  '사진형',
  '안전표지형',
  '일러스트형',
  '동영상형',
] satisfies WrittenTestProblemCategory[];

export const TestCategorySelectBottomSheet = () => {
  const router = useRouter();
  const { data: studyProgressData } = useGetStudyProgress();

  const isVisible = useWrittenTestStore((s) => s.categorySelect === '열림');
  const dispatch = useWrittenTestStore((s) => s.dispatch);
  const hide = () => {
    dispatch({ type: '유형선택닫기' });
  };

  return (
    <BottomSheet isVisible={isVisible} setIsVisible={hide}>
      <div className="rounded-t-[24px] bg-white px-20 pb-24 pt-32">
        <button type="button" className="absolute right-24 top-32" onClick={hide}>
          <NewIcon icon="x-black-24" color="gray-700" size={24} />
        </button>
        <div className="text-[22px] font-bold">문제 유형별로 공부</div>
        <div className="text-T5 mb-25 mt-[7.5px] text-gray-600">
          선택하신 면허 종류에 맞게 안내드릴게요.
        </div>
        {TEST_BANK_BY_CATEGORIES.map((category, i) => {
          const { type, choicesNum, answerNum, text, score, problemsNum } = category;

          const writtenTestCategory = WRITTEN_TEST_PROBLEM_CATEGORY_LIST[i];
          const lastProblemIndex =
            studyProgressData?.category?.[writtenTestCategory]?.last_problem_index ?? 0;

          return (
            <button
              type="button"
              key={`${type}-${text}-${answerNum}`}
              className="border-gray-250 mb-10 flex w-full items-center rounded-[8px] border-[1.2px] py-14 pl-20"
              onClick={() => {
                dispatch({ type: '학습시작' });
                router.push(
                  `/test/learn/problems/category/${writtenTestCategory}?q=${lastProblemIndex}`,
                );
              }}
            >
              <div className="text-Body1 mr-[10px]">{text}</div>
              <div className="text-Body5 text-gray-600">{`${choicesNum}지 ${answerNum}답 (${score}점) / ${problemsNum}문제`}</div>
            </button>
          );
        })}
      </div>
    </BottomSheet>
  );
};
