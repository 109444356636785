import { useState } from 'react';

import { Icon, IconType } from 'design-system';
import { useRouter } from 'next/router';

import { TestCategorySelectBottomSheet } from '@templates/Test/Test/components/TestCategorySelectBottomSheet';

const contents = [
  {
    icon: 'test-category',
    text: (
      <span className="text-Body1 whitespace-nowrap">
        시험 유형별로 <br /> 공부하기
      </span>
    ),
    path: '',
  },
  {
    icon: 'test-timer',
    text: (
      <span className="text-Body1">
        실전 모의고사
        <br />
        40문제
      </span>
    ),
    path: '/test/exam',
  },
  {
    icon: 'test-graph',
    text: (
      <span className="text-Body1">
        사람들이 많이
        <br />
        틀린 문제
      </span>
    ),
    path: '/test/most-wrong',
  },
];
export const HomeTest = () => {
  const router = useRouter();
  const [isCategorySelectVisible, setIsCategorySelectVisible] = useState(false);
  return (
    <>
      <div className="mt-12 rounded-[10px] px-16 pb-40">
        <div className="flex w-full flex-row items-center justify-between">
          <div className="text-SubTitle">필기 준비하러 가기</div>
          <div
            className="text-Body4 cursor-pointer text-gray-600"
            onClick={() => router.push('/test')}
          >
            전체보기
          </div>
        </div>
        <div className="scrollbar-hide mt-16 flex gap-10 overflow-x-scroll">
          {contents.map((content, i) => {
            const { icon, text, path } = content;
            return (
              <div
                key={i}
                className="bg-gray-150 pl-17 pt-19 relative h-[150px] w-[150px] shrink-0 rounded-[14px] pb-12 pr-12"
                onClick={() => {
                  if (path.length) {
                    router.push(path);
                  } else if (icon === 'test-category') {
                    setIsCategorySelectVisible(true);
                  }
                }}
              >
                <div>
                  <div>{text}</div>
                </div>
                <div className="absolute bottom-12 right-12 rounded-full bg-white">
                  <Icon icon={icon as IconType} size={52} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <TestCategorySelectBottomSheet />
    </>
  );
};
