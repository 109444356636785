import { NewIcon } from 'design-system';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Loading } from '@components';

import { useGetLocation } from '@hooks';

export const FindAcademyAndReservation = () => {
  const router = useRouter();

  const { getLocationWhileRequestingPermission, isFunctionLoading } = useGetLocation({
    isSilent: true,
  });

  return (
    <>
      {isFunctionLoading && <Loading />}
      <div className="py-22 mt-10 rounded-lg bg-white px-16">
        <Link className="gap-18 flex cursor-pointer flex-row items-center pt-10" href="/explore">
          <div>
            <div className="text-Title1">내 주변 운전 학원 찾고 예약하기</div>
            <div className="text-Body4 mt-9 text-gray-600">
              1분안에 운전학원을 예약할 수 있어요!
            </div>
          </div>
          <Image
            src="/home-car.png"
            alt="홈 운전학원 예약 이미지"
            width={106}
            height={101}
            className="h-[101px] w-[106px]"
            priority={true}
          />
        </Link>
        <button
          className="mt-29 bg-gray-150 flex w-full flex-row items-center justify-between rounded-[10px] px-16 py-14"
          onClick={() => router.push('/explore/search')}
        >
          <div className="text-Body1 text-gray-400">지역명, 학원명으로 찾아보세요.</div>
          <NewIcon icon="magnifier-outlined" size={24} className="fill-new-gray-900" />
        </button>
        <div className="mt-12">
          <button
            className="border-gray-250 flex w-full cursor-pointer flex-row items-center justify-center gap-3 rounded-[26px] border-[1.6px] py-14"
            onClick={async () => {
              const {
                myLocation: { latitude, longitude },
              } = await getLocationWhileRequestingPermission();
              await router.push('/explore?lat=' + latitude + '&lng=' + longitude + '&address=');
            }}
          >
            <NewIcon icon="mapmark-filled" size={20} className="fill-new-gray-900" />
            <div className="text-Body3">지금 내 위치에서 찾아보기</div>
          </button>
        </div>
      </div>
    </>
  );
};
