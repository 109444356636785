interface SkeletonProps {
  type:
    | 'List-Image-S'
    | 'List-Image-M'
    | 'List-Image-M-primary'
    | 'List-Image-L'
    | 'List-Image-S-Horizontal';
}

export const Skeleton = ({ type }: SkeletonProps) => {
  if (type === 'List-Image-S-Horizontal') {
    return (
      <div className='flex gap-10'>
        <div>
          <div className='bg-gray-250 h-[128px] w-[200px] rounded-[12px]' />
          <div className='bg-gray-250 mt-10 h-[13.73px] w-[64px] rounded-[3px]' />
          <div className='bg-gray-250 mt-10 h-[13.73px] w-[58px] rounded-[3px]' />
          <div className='bg-gray-250 mt-10 h-[13.73px] w-[42px] rounded-[3px]' />
        </div>
        <div>
          <div className='bg-gray-250 h-[128px] w-[200px] rounded-[12px]' />
          <div className='bg-gray-250 mt-10 h-[13.73px] w-[64px] rounded-[3px]' />
          <div className='bg-gray-250 mt-10 h-[13.73px] w-[58px] rounded-[3px]' />
          <div className='bg-gray-250 mt-10 h-[13.73px] w-[42px] rounded-[3px]' />
        </div>
      </div>
    );
  }

  if (type === 'List-Image-S') {
    return (
      <div>
        <div className='flex gap-10'>
          <div className='bg-gray-250 h-[128px] w-[200px] rounded-[12px]' />
        </div>
        <div className='bg-gray-250 mt-10 h-[13.73px] w-[64px] rounded-[3px]' />
        <div className='bg-gray-250 mt-10 h-[13.73px] w-[160px] rounded-[3px]' />
        <div className='bg-gray-250 mt-10 h-[13.73px] w-[120px] rounded-[3px]' />
      </div>
    );
  }

  if (type === 'List-Image-M') {
    return (
      <>
        <div className='flex w-full justify-between'>
          <div>
            <div className='bg-gray-250 h-[16px] w-[62px] rounded-[3px]' />
            <div className='bg-gray-250 mt-10 h-[16px] w-[242px] rounded-[3px]' />
            <div className='bg-gray-250 mt-10 h-[16px] w-[162px] rounded-[3px]' />
            <div className='bg-gray-250 mt-10 h-[16px] w-[190px] rounded-[3px]' />
          </div>
          <div className='bg-gray-250 h-[72px] w-[96px] rounded-[8px]' />
        </div>
        <div className='mt-34 flex w-full justify-between'>
          <div>
            <div className='bg-gray-250 h-[16px] w-[62px] rounded-[3px]' />
            <div className='bg-gray-250 mt-10 h-[16px] w-[242px] rounded-[3px]' />
            <div className='bg-gray-250 mt-10 h-[16px] w-[162px] rounded-[3px]' />
            <div className='bg-gray-250 mt-10 h-[16px] w-[190px] rounded-[3px]' />
          </div>
          <div className='bg-gray-250 h-[72px] w-[96px] rounded-[8px]' />
        </div>
      </>
    );
  }

  if (type === 'List-Image-M-primary') {
    return (
      <>
        <div className='flex w-full justify-between'>
          <div>
            <div className='bg-gray-250 mt-10 h-[16px] w-[162px] rounded-[3px]' />
            <div className='bg-gray-250 mt-10 h-[16px] w-[62px] rounded-[3px]' />
          </div>
          <div className='bg-gray-250 h-[72px] w-[96px] rounded-[8px]' />
        </div>
        <div className='mt-34 flex w-full justify-between'>
          <div>
            <div className='bg-gray-250 mt-10 h-[16px] w-[162px] rounded-[3px]' />
            <div className='bg-gray-250 mt-10 h-[16px] w-[62px] rounded-[3px]' />
          </div>
          <div className='bg-gray-250 h-[72px] w-[96px] rounded-[8px]' />
        </div>
        <div className='mt-34 flex w-full justify-between'>
          <div>
            <div className='bg-gray-250 mt-10 h-[16px] w-[162px] rounded-[3px]' />
            <div className='bg-gray-250 mt-10 h-[16px] w-[62px] rounded-[3px]' />
          </div>
          <div className='bg-gray-250 h-[72px] w-[96px] rounded-[8px]' />
        </div>
      </>
    );
  }

  if (type === 'List-Image-L') {
    return (
      <div>
        <div className='flex gap-10'>
          <div className='bg-gray-250 h-[125px] w-[200px] rounded-[12px]' />
          <div className='bg-gray-250 h-[125px] w-[200px] rounded-[12px]' />
        </div>
        <div className='bg-gray-250 mt-10 h-[16px] w-[64px] rounded-[3px]' />
        <div className='bg-gray-250 mt-10 h-[16px] w-[272px] rounded-[3px]' />
        <div className='bg-gray-250 mt-10 h-[16px] w-[190px] rounded-[3px]' />
        <div className='mt-36 flex gap-10'>
          <div className='bg-gray-250 h-[125px] w-[200px] rounded-[12px]' />
          <div className='bg-gray-250 h-[125px] w-[200px] rounded-[12px]' />
        </div>
        <div className='bg-gray-250 mt-10 h-[16px] w-[64px] rounded-[3px]' />
        <div className='bg-gray-250 mt-10 h-[16px] w-[272px] rounded-[3px]' />
        <div className='bg-gray-250 mt-10 h-[16px] w-[190px] rounded-[3px]' />
      </div>
    );
  }

  return <></>;
};
