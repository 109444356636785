import { useGetTrendyBlogs } from 'database';
import { STICKER_SIZE, Sticker } from 'design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Skeleton } from '@components';

export const HomeBlog = () => {
  const router = useRouter();

  const { data: blogData = [], isLoading } = useGetTrendyBlogs();

  if (isLoading)
    return (
      <div className='mt-16 w-full'>
        <Skeleton type='List-Image-M' />
      </div>
    );

  return (
    <>
      {blogData
        .filter((v) => v.isBest)
        .map((v, i) => {
          const { title, image, id, hashTags } = v;

          const onClickBlogItem = () => {
            router.push(`/blog/${id}`);
          };

          return (
            <div
              key={i}
              className='gap-30 mt-16 grid w-full cursor-pointer grid-cols-[1fr_90px] items-center justify-between'>
              <div onClick={onClickBlogItem}>
                <Sticker size={STICKER_SIZE.SMALL} text='인기' />
                <div className='text-Section-Title mt-9'>{title}</div>
                <div className='flex gap-10'>
                  {hashTags.slice(0, 3).map((hashTag) => (
                    <div className='text-Body5 mt-7 text-gray-600' key={hashTag}>
                      # {hashTag}
                    </div>
                  ))}
                </div>
              </div>
              <div className='h-68 relative w-full overflow-hidden rounded-md'>
                <Image
                  className='h-68 object-cover'
                  src={image}
                  height={70}
                  width={160}
                  alt={title}
                  priority
                />
              </div>
            </div>
          );
        })}
    </>
  );
};
